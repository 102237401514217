import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserContext, storeUserContext } from "../../auth/auth-context";
import { AuthContext } from "../../context/AuthContext";
import { get } from "../../data/apiRequest";
import { parse } from "../../utils/json-util";
export interface IAcademicYear {
  id: number;
  name: string;
  startingDate: Date;
  endingDate: Date;
  isActive: boolean;
}

const ACADEMIC_YEAR_PATH = "/configurations/academic-year";
export function AcademicYearSwitching() {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useQuery<IAcademicYear[]>(
    ["academicYear"],
    () => get("AcademicYear").then((res) => res.data),
    {
      onSuccess: (response) => {
        if (!response.length && location.pathname !== ACADEMIC_YEAR_PATH) {
          navigate(`${ACADEMIC_YEAR_PATH}?add`);
        }
      },
    }
  );
  const { authContext, setAuthContext } = useContext(AuthContext);
  const userContext = parse(getUserContext());

  if (isLoading) {
    return null;
  }

  const handleChange = (e: any) => {
    let id = e.target.value;

    const item = data?.find((x: any) => x.id === +id);
    if (item) {
      setAuthContext({
        ...authContext,
        academicYearId: item.id,
      });

      storeUserContext({
        ...userContext,
        academicYear: {
          name: item.name,
          id: item.id,
          startingDate: item.startingDate,
          endingDate: item.endingDate,
        },
      });

      window.location.reload();
    }
  };
  return (
    <div className="mr-3">
      <FormControl>
        <Select
          sx={{
            "&": {
              width: "119px",
            },
            "& #academic-year-switch-select": {
              fontSize: "14px",
            },
          }}
          labelId="academic-year-switch-label"
          id="academic-year-switch-select"
          value={authContext.academicYear.id}
          onChange={handleChange}
          size={"small"}
        >
          {data?.map((year: any) => {
            return (
              <MenuItem key={year.id} value={year.id}>
                {year.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
