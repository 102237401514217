import { Link, Outlet } from "react-router-dom";
import { SideMenu } from "../customer-admin/SideMenu";
import scholaLogo from "../../assets/images/schola-logo.svg";
import { get } from "../../data/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { ClipLoader } from "react-spinners";
import { SelectActiveInstitute } from "../customer-admin/SelectCustomer";
import { Suspense, useState } from "react";
import {
  selectActiveInstitute,
  setActiveInstitute,
} from "../customer-admin/util";
import isEmpty from "lodash/isEmpty";
import Alert from "@mui/material/Alert";

export function AdminLayout() {
  const [activeInstitute, setActiveInstituteInState] = useState(
    selectActiveInstitute()
  );
  const { data, isLoading, isFetched, isError } = useQuery(["institute"], () =>
    get("/Institute")
  );
  const handleCustomerChange = (customer: any) => {
    setActiveInstitute(customer);
    setActiveInstituteInState(customer);
  };
  return (
    <div className="flex bg-slate-50">
      <div className="shadow-lg h-screen sticky top-0 overflow-auto bg-white w-64 pb-20">
        <Link to={"/home"}>
          <img src={scholaLogo} className="ml-8 my-2" alt="" />
        </Link>
        {!isLoading && !isError && <SideMenu />}
      </div>
      <div className="admin-content-area w-full" style={{ maxWidth: 1200 }}>
        <div className="p-10">
          {isEmpty(activeInstitute) && (
            <SelectActiveInstitute
              onChange={handleCustomerChange}
              data={data?.data ?? []}
            />
          )}
          {!isEmpty(activeInstitute) && (
            <>
              {isLoading && (
                <div className="text-center p-10">
                  <ClipLoader />
                </div>
              )}
              {isFetched && !isError && (
                <Suspense
                  fallback={
                    <div>
                      <ClipLoader />
                    </div>
                  }
                >
                  <Outlet />
                </Suspense>
              )}
              {!isLoading && isError && (
                <Alert severity="error">Failed to load institute details</Alert>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
