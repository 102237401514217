import { Link, useLocation } from "react-router-dom";
import { UserNavbar } from "../layouts/UserNavbar";
import { AcademicYearSwitching } from "../layouts/AcademicYearSwitching";
import SchoolIcon from "@mui/icons-material/School";
import StoreIcon from "@mui/icons-material/Store";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import BusAlertIcon from "@mui/icons-material/BusAlert";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddCardIcon from "@mui/icons-material/AddCard";
import SettingsIcon from "@mui/icons-material/Settings";
import PaymentIcon from "@mui/icons-material/Payment";
import ComputerIcon from "@mui/icons-material/Computer";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

interface SubMenu {
  link: string;
  name: string;
}
interface MenuWithSubChild {
  link: string;
  name: string;
  icon: React.ReactNode;
  subMenu?: SubMenu[];
}

const instituteAdminMenus: MenuWithSubChild[] = [
  {
    link: "/home",
    name: "Dashboard",
    icon: <DashboardIcon fontSize="small" />,
  },
  {
    link: "/admission",
    name: "Admission",
    icon: <SchoolIcon fontSize="small" />,
    subMenu: [{ link: "/admission/class-division", name: "Class Division" }],
  },
  {
    link: "/modules",
    name: "Modules",
    icon: <AutoAwesomeMosaicIcon fontSize="small" />,
  },
  {
    link: "/institutes",
    name: "Institutes",
    icon: <AssuredWorkloadIcon fontSize="small" />,
  },
  {
    link: "/roles",
    name: "Roles",
    icon: <SupervisedUserCircleIcon fontSize="small" />,
  },
  {
    link: "/users",
    name: "Users",
    icon: <GroupIcon fontSize="small" />,
  },
  {
    link: "/finance",
    name: "Finance",
    icon: <SchoolIcon fontSize="small" />,
    subMenu: [
      {
        link: "/finance/finance-ledger",
        name: "Finance Ledger",
      },
      {
        link: "/finance/ledger-group",
        name: "Ledger Group",
      },
      {
        link: "/finance/bank-accounts",
        name: "Bank Accounts",
      },
      {
        link: "/finance/payment-voucher",
        name: "Payment Voucher",
      },
      {
        link: "/finance/receipt-voucher",
        name: "Receipt Voucher",
      },
      {
        link: "/finance/journal-voucher",
        name: "Journal Voucher",
      },
      {
        link: "/finance/deposit",
        name: "Deposit",
      },
      {
        link: "/finance/withdrawal",
        name: "Withdrawal",
      },
      {
        link: "/finance/opening-balance",
        name: "Opening Balance",
      },
      {
        link: "/finance/Pdc",
        name: "PDC",
      },
    ],
  },
  {
    link: "/finance/reports",
    name: "Reports",
    icon: <SchoolIcon fontSize="small" />,
    subMenu: [
      {
        link: "/finance/reports/day-book",
        name: "Day Book",
      },
      {
        link: "/finance/reports/books",
        name: "Books",
      },
      {
        link: "/finance/reports/profile-and-loss-account",
        name: "Profit & Loss",
      },
      {
        link: "/finance/reports/income-statement",
        name: "Income Statement",
      },
      {
        link: "/finance/reports/trial-balance",
        name: "Trial Balance",
      },
      {
        link: "/finance/reports/seasonal-trial-balance",
        name: "Seasonal Trial Balance",
      },
      {
        link: "/finance/reports/monthly-trial-balance",
        name: "Monthly Trial Balance",
      },
      {
        link: "/finance/reports/receipts-and-payments",
        name: "Receipts & Payments",
      },
      {
        link: "/finance/reports/daily-closing",
        name: "Daily Closing",
      },
      {
        link: "/finance/reports/balance-sheet",
        name: "Balance Sheet",
      },
    ],
  },
  {
    link: "/transportation",
    name: "Transportation",
    icon: <SchoolIcon fontSize="small" />,
    subMenu: [
      {
        link: "/transportation/bus-document-master",
        name: "Bus Document Master",
      },
      { link: "/transportation/bus", name: "Bus" },
      { link: "/transportation/bus-points", name: "Bus Points" },
      { link: "/transportation/bus-route", name: "Bus Route" },
      { link: "/transportation/bus-fee-config", name: "Bus Fee Configuration" },
      { link: "/transportation/add-to-bus", name: "Add To Bus" },
    ],
  },
  {
    link: "/transportation/reports",
    name: "Reports",
    icon: <SchoolIcon fontSize="small" />,
    subMenu: [
      {
        link: "/transportation/reports/transportation-collection-report",
        name: "Collection Report",
      },
      {
        link: "/transportation/reports/students-report",
        name: "Students Report",
      },
      {
        link: "/transportation/reports/transportation-fee-summary",
        name: "Fee Summary",
      },
      {
        link: "/transportation/reports/transportation-document-report",
        name: "Document Report",
      },
      {
        link: "/transportation/reports/attendance-sheet",
        name: "Attendance Sheet",
      },
      {
        link: "/transportation/reports/student-wise-fee",
        name: "Student-Wise Fee",
      },
    ],
  },
  {
    link: "/store",
    name: "Store",
    icon: <StoreIcon fontSize="small" />,
    subMenu: [
      {
        link: "/store/bundles",
        name: "Bundles",
      },
      {
        link: "/store/wareHouses",
        name: "WareHouses",
      },
      {
        link: "/store/suppliers",
        name: "Suppliers",
      },
      {
        link: "/store/item-group",
        name: "ItemGroup",
      },
      {
        link: "/store/items",
        name: "Items",
      },
    ],
  },
  {
    link: "/fee/fee-configuration",
    name: "Fee Config",
    icon: <AddCardIcon fontSize="small" />,
    subMenu: [
      {
        link: "/fee/change-fee-rule",
        name: "Change Fee Rule",
      },
      {
        link: "/fee/fine-engine",
        name: "Fine Engine",
      },
      {
        link: "/fee/fee-settings",
        name: "Fee Settings",
      },
      {
        link: "/fee/fee-banks",
        name: "Fee-Banks",
      },
    ],
  },

  {
    link: "/fee/fee-collection",
    name: "Fee Collection",
    icon: <CreditScoreIcon fontSize="small" />,
  },
  {
    link: "/fee/concession",
    name: "Concession",
    icon: <BusAlertIcon fontSize="small" />,
  },
  {
    link: "/fee",
    name: "Fee",
    icon: <PaymentIcon fontSize="small" />,
    subMenu: [
      {
        link: "/fee/installment-group",
        name: "Installment Group",
      },
      {
        link: "/fee/installment-master",
        name: "Installment Master",
      },
      {
        link: "/fee/fee-master",
        name: "Fee Master",
      },
      {
        link: "/fee/bill-book",
        name: "Bill-Book",
      },

      {
        link: "/fee/rule-group",
        name: "Rule Group",
      },
      {
        link: "/fee/fee-rule",
        name: "Fee Rule",
      },
    ],
  },
  {
    link: "/fee/reports",
    name: "Reports",
    icon: <FileCopyIcon fontSize="small" />,
    subMenu: [
      {
        link: "/fee/reports/due-report",
        name: "Due Report",
      },
      {
        link: "/fee/reports/collection-report",
        name: "Collection Report",
      },
      {
        link: "/fee/reports/invoice-report",
        name: "Invoice Report",
      },
      {
        link: "/fee/reports/my-collection",
        name: "My Collection",
      },
      {
        link: "/fee/reports/fee-summary",
        name: "Fee Summary",
      },
      {
        link: "/fee/reports/collection-summary",
        name: "Collection Summary",
      },
      {
        link: "/fee/reports/installment-summary",
        name: "Installment Summary",
      },
      {
        link: "/fee/reports/student-fee-summary",
        name: "Student Fee Summary",
      },
      {
        link: "/fee/reports/advance-report",
        name: "Advance Report",
      },
      {
        link: "/fee/reports/concession-report",
        name: "Concession Report",
      },
    ],
  },
  {
    link: "/attendance",
    name: "Attendance",
    icon: <CalendarMonthIcon fontSize="small" />,
    subMenu: [
      {
        link: "/attendance/take-attendance",
        name: "Take Attendance",
      },
      {
        link: "/attendance/leave-type",
        name: "Leave Types",
      },
      {
        link: "/attendance/off-days",
        name: "Off Days",
      },
      {
        link: "/attendance/att-hours",
        name: "Attendance Hours",
      },
      {
        link: "/attendance/late-early-entry",
        name: "Late Early Entry",
      },
      {
        link: "/attendance/leave-request",
        name: "Leave Request",
      },
      {
        link: "/attendance/student-group",
        name: "Student Group",
      },
    ],
  },
  {
    link: "/attendance/reports",
    name: "Reports",
    icon: <FileCopyIcon fontSize="small" />,
    subMenu: [
      {
        link: "/attendance/reports/attendance-status",
        name: "Attendance Status",
      },
      {
        link: "/attendance/reports/monthly-attendance",
        name: "Monthly Attendance",
      },
      {
        link: "/attendance/reports/attendance-summary",
        name: "Attendance Summary",
      },
      {
        link: "/attendance/reports/student-wise-attendance",
        name: "Student-wise Attendance",
      },
      {
        link: "/attendance/reports/daily-summary",
        name: "Daily Summary Report",
      },
    ],
  },
  {
    link: "/masters",
    name: "Masters",
    icon: <ComputerIcon fontSize="small" />,
    //
    subMenu: [
      {
        link: "/masters/religion",
        name: "Religion",
      },
      {
        link: "/masters/community",
        name: "Community",
      },
      {
        link: "/masters/category",
        name: "Category",
      },
      {
        link: "/masters/nationality",
        name: "Nationality",
      },
      {
        link: "/masters/state",
        name: "State",
      },
      {
        link: "/masters/district",
        name: "District",
      },
      {
        link: "/masters/language",
        name: "Language",
      },
      {
        link: "/masters/auxiliary-subject",
        name: "Auxiliary-Subject",
      },
      {
        link: "/masters/mother-tongue",
        name: "Mother-Tongue",
      },
      {
        link: "/masters/house-group",
        name: "House-Group",
      },
      {
        link: "/masters/profession",
        name: "Profession",
      },
      {
        link: "/masters/document",
        name: "Document",
      },
    ],
  },
  {
    link: "/configurations",
    name: "Configurations",
    icon: <SettingsIcon fontSize="small" />,
    subMenu: [
      {
        link: "/configurations/academic-year",
        name: "Academic Year",
      },
      {
        link: "/configurations/financial-year",
        name: "Financial Year",
      },
      {
        link: "/configurations/class",
        name: "Class",
      },
      {
        link: "/configurations/course",
        name: "Course",
      },
      {
        link: "/configurations/division",
        name: "Division",
      },
      {
        link: "/configurations/subject-master",
        name: "Subject-Master",
      },
    ],
  },
];

export function SideMenu() {
  const location = useLocation();
  const menus = instituteAdminMenus;
  const isActive = (menu: any) => location.pathname === menu.link;

  const addFilter = (x: any) => {
    const { pathname } = location;
    const [, start] = pathname.split("/");
    return x.link.startsWith(`/${start}`);
  };
  return (
    <>
      <ul>
        {menus.filter(addFilter).map((menu: any, index: number) => (
          <Menu
            active={isActive(menu)}
            link={menu.link}
            name={menu.name}
            icon={menu.icon}
            key={index}
            children={menu.subMenu}
          />
        ))}
      </ul>
      <div className="border-t border-slate-100 pl-12 flex fixed bottom-0 left-0 bg-white justify-center py-2">
        <AcademicYearSwitching />
        <UserNavbar />
      </div>
    </>
  );
}

const MENU_ACTIVE_CLASS = "bg-teal-400 text-white";
const Menu = ({
  name,
  icon,
  link,
  active = false,
  children,
}: {
  name: string;
  icon: string;
  link: string;
  active?: boolean;
  children?: any[];
}) => {
  const { pathname } = useLocation();
  return (
    <li>
      <Link
        to={link}
        className={`flex items-center py-2 px-2 pr-12 font-normal text-black/60 hover:bg-teal-50 hover:text-black/60 ${
          active ? MENU_ACTIVE_CLASS : ""
        }`}
      >
        <span className="mr-2 -mt-1 ml-2">{icon}</span>
        {name}
      </Link>
      {children && (
        <ul>
          {children.map((subMenu) => (
            <li key={subMenu.link}>
              <Link
                className={`flex py-3 px-2 pr-4 pl-12 text-sm text-black/60 hover:bg-teal-50 hover:text-black/60 text-white ${
                  pathname.endsWith(subMenu.link) ? MENU_ACTIVE_CLASS : ""
                }`}
                to={subMenu.link}
              >
                {subMenu.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
