import { SCHOLA_ACTIVE_INSTITUTE } from "../modules/customer-admin/util";

export const STORAGE_KEYS = {
  USER_CONTEXT: "schola_user_context",
};
export const getUserContext = () => {
  // if user is not checked remember me, then token is stored inside sessionStorage
  return (
    localStorage.getItem(STORAGE_KEYS.USER_CONTEXT) ||
    sessionStorage.getItem(STORAGE_KEYS.USER_CONTEXT)
  );
};

export const storeUserContext = (loginResponse: {}, persist = true) => {
  persist
    ? localStorage.setItem(
        STORAGE_KEYS.USER_CONTEXT,
        JSON.stringify(loginResponse)
      )
    : sessionStorage.setItem(
        STORAGE_KEYS.USER_CONTEXT,
        JSON.stringify(loginResponse)
      );
};

export const removeUserContext = () => {
  localStorage.removeItem(STORAGE_KEYS.USER_CONTEXT);
  sessionStorage.removeItem(STORAGE_KEYS.USER_CONTEXT);
  sessionStorage.removeItem(SCHOLA_ACTIVE_INSTITUTE);
  localStorage.removeItem(SCHOLA_ACTIVE_INSTITUTE);
};
