import React from "react";
import { FormLabel } from "./FormLabel";

export function AppLabel({
  name,
  classNames,
  children,
  htmlFor,
}: {
  name: string;
  htmlFor?: string;
  classNames?: string | string[];
  children?: React.ReactNode;
}) {
  return (
    <FormLabel name={name} htmlFor={htmlFor} classNames={classNames}>
      {children}
    </FormLabel>
  );
}
