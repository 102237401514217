import { useQuery } from "@tanstack/react-query";
import { get } from "../../../data/apiRequest";

const queryKeys = {
  FINANCE_LEDGER: ["financeLedger"],
  OPENING_BALANCE: ["openingBalance"],
};

export enum VoucherType {
  payment = "P",
  receipt = "R",
  journal = "J",
  deposit = "D",
  withdrawal = "W",
}

export const VoucherNames: { [key: string]: string } = {
  [VoucherType.receipt]: "Receipt",
  [VoucherType.withdrawal]: "Withdrawal",
  [VoucherType.payment]: "Payment",
  [VoucherType.journal]: "Journal",
  [VoucherType.deposit]: "Deposit",
};

export interface FinanceLedger {
  id: number;
  code: string;
  name: string;
  ledgerGroupId: number;
  isStandardField: boolean;
  ledgerGroup: string;
  isTaxLedger: boolean;
}

export function useFinanceLedger() {
  return useQuery<FinanceLedger[]>(queryKeys.FINANCE_LEDGER, () =>
    get("financeLedger").then((res) => res.data)
  );
}

export function useFinanceLedgerByGroupId(
  id: number | string,
  onSettled?: (data: FinanceLedger[]) => void
) {
  return useQuery<FinanceLedger[]>(
    [...queryKeys.FINANCE_LEDGER, id],
    () => get(`financeLedger?groupId=${id}`).then((res) => res.data),
    {
      enabled: !!id,
      onSuccess(data) {
        onSettled?.(data);
      },
    }
  );
}

export interface OpeningBalanceType {
  id: number;
  amount: number;
  ledgerId: number;
  ledgerGroupId: number;
  drOrCr: string;
  ledgerName: string;
}

export function useOpeningBalance(
  onSettled?: (data: OpeningBalanceType[]) => void
) {
  return useQuery<OpeningBalanceType[]>(
    queryKeys.OPENING_BALANCE,
    () => get("openingBalance").then((res) => res.data),
    {
      onSuccess(data) {
        onSettled?.(data);
      },
    }
  );
}

export interface FinLedger {
  id: number;
  groupName: string;
  isStandardField: boolean;
  drOrCr: string;
  parentGroup?: any;
  parentId?: any;
}

export function useFinLedger() {
  const queryKey = ["_ledgerGroup"];
  return useQuery<FinLedger[]>(queryKey, () =>
    get("ledgerGroup").then((res) => res.data)
  );
}

interface Balance {
  amount: number;
  drOrCr: string;
}

export function useLedgerBalance(ledgerId: number | string) {
  return useQuery<Balance>(
    ["`FinanceLedger/Balance", ledgerId],
    () =>
      get(`FinanceLedger/Balance?ledgerId=${ledgerId}`).then((res) => res.data),
    {
      enabled: !!ledgerId,
    }
  );
}
