import { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { storeUserContext } from "../../auth/auth-context";
import { post } from "../../data/apiRequest";
import { LoginForm } from "./LoginForm";
import { LoginSchema } from "./helper";
import { showError, showSuccess } from "../../components/Toast";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export function Login() {
  const navigate = useNavigate();
  const { setAuthContext } = useContext(AuthContext);

  const login = useMutation(
    (values: LoginSchema) => {
      return post("Login", values);
    },
    {
      onSuccess: (response: AxiosResponse, variables) => {
        storeUserContext(response.data, variables.rememberMe);
        setAuthContext(response.data);
        navigate("/home");
      },
      onError: (e: AxiosError) => {
        showError("Invalid credentials");
      },
    }
  );

  const handleSubmit = (values: LoginSchema) => {
    login.mutate(values);
  };

  return (
    <>
      <h3 className="text-3xl mb-6 font-semibold">Login</h3>
      <LoginForm handleSubmit={handleSubmit} isSubmitting={login.isLoading} />
      <div className="flex justify-between">
        Not registered?{" "}
        <Link to={"/register"} className="text-teal-500">
          Create Account
        </Link>
      </div>
    </>
  );
}
