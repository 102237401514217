import { useFormik } from "formik";
import { AppInput } from "../../components/AppInput";
import { Button } from "../../components/Button";
import { ErrorMessage } from "../../components/ErrorMessage";
import { AppLabel } from "../../components/form/AppLabel";
import { validateSignUpForm } from "./helper";

export function RegisterForm({
  handleSubmit,
  isSubmitting,
}: {
  handleSubmit: any;
  isSubmitting: boolean;
}) {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      institute_name: "",
    },
    validate: validateSignUpForm,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const { values, handleChange } = formik;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-2">
        <AppLabel name="Name" classNames={"mb-1 block"}>
          <AppInput name="name" value={values.name} onChange={handleChange} />
          {formik.errors.name && formik.touched.name && (
            <ErrorMessage text={formik.errors.name} />
          )}
        </AppLabel>
      </div>

      <div className="mb-2">
        <AppLabel name="E-mail" classNames={"mb-1 block"}>
          <AppInput
            name="email"
            type="email"
            value={values.email}
            onChange={handleChange}
          />
          {formik.errors.email && formik.touched.email && (
            <ErrorMessage text={formik.errors.email} />
          )}
        </AppLabel>
      </div>

      <div className="mb-2">
        <AppLabel name="Institute Name" classNames={"mb-1 block"}>
          <AppInput
            name="institute_name"
            value={values.institute_name}
            onChange={handleChange}
          />
        </AppLabel>
      </div>

      <Button type="submit" text="Create my account" disabled={isSubmitting} />
    </form>
  );
}
