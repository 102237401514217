import ReactDOM from "react-dom/client";
import { StrictMode, useState, lazy } from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import { getUserContext } from "./auth/auth-context";
import "./index.scss";
import { Login } from "./modules/onboard/Login";
import { Register } from "./modules/onboard/Register";
import { OnboardLayout } from "./modules/onboard/OnboardingLayout";
import { AdminLayout } from "./modules/layouts/AdminLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthMiddleware } from "./middleware/AuthMiddleware";
import { LandingPageLayout } from "./modules/landing-page/LandingPageLayout";
import Dashboard from "./modules/customer-admin/dashboard";
import { parse } from "./utils/json-util";
import { VoucherType } from "./modules/institute-admin/finance/data";
import { ProfitAndLossAccountType } from "./modules/institute-admin/finance/reports/ProfitAndLossAccount";

const BankAccount = lazy(
  () => import("./modules/institute-admin/finance/BankAccounts")
);

const PaymentOrReceipt = lazy(
  () => import("./modules/institute-admin/finance/PaymentOrReceipt")
);
const Pdc = lazy(() => import("./modules/institute-admin/finance/Pdc"));
const JournalVoucher = lazy(
  () => import("./modules/institute-admin/finance/JournalVoucher")
);

const DepositOrWithdrawal = lazy(
  () => import("./modules/institute-admin/finance/DepositOrWithdrawal")
);

const OpeningBalance = lazy(
  () => import("./modules/institute-admin/finance/OpeningBalance")
);

const DayBook = lazy(
  () => import("./modules/institute-admin/finance/reports/DayBook")
);

const Books = lazy(
  () => import("./modules/institute-admin/finance/reports/Books")
);

const ProfitAndLossAccount = lazy(
  () => import("./modules/institute-admin/finance/reports/ProfitAndLossAccount")
);

const TrialBalance = lazy(
  () => import("./modules/institute-admin/finance/reports/TrialBalance")
);

const SeasonalTrialBalance = lazy(
  () => import("./modules/institute-admin/finance/reports/SeasonalTrialBalance")
);

const MonthlyTrialBalance = lazy(
  () => import("./modules/institute-admin/finance/reports/MonthlyTrialBalance")
);

const ReceiptAndPayment = lazy(
  () => import("./modules/institute-admin/finance/reports/ReceiptsAndPayments")
);

const DailyClosing = lazy(
  () => import("./modules/institute-admin/finance/reports/DailyClosing")
);

const BalanceSheet = lazy(
  () => import("./modules/institute-admin/finance/reports/BalanceSheet")
);

const ConcessionRequest = lazy(
  () => import("./modules/institute-admin/fee/ConcessionRequest")
);

const InstallmentGroup = lazy(
  () => import("./modules/customer-admin/fee/InstallmentGroup")
);
const InstallmentMaster = lazy(
  () => import("./modules/customer-admin/fee/InstallmentMaster")
);
const FeeMaster = lazy(() => import("./modules/customer-admin/fee/FeeMaster"));
const FeeLayout = lazy(
  () => import("./modules/institute-admin/fee-configuration/FeeLayout")
);
const FineEngine = lazy(
  () => import("./modules/institute-admin/fee/FineEngine")
);
const FeeBanks = lazy(() => import("./modules/institute-admin/fee/FeeBanks"));

const FeeSettings = lazy(
  () => import("./modules/customer-admin/fee/FeeSettings")
);

const FinanceLedger = lazy(
  () => import("./modules/institute-admin/finance/FinanceLedger")
);
const LedgerGroup = lazy(
  () => import("./modules/institute-admin/finance/LedgerGroup")
);
const BillBook = lazy(() => import("./modules/customer-admin/fee/BillBook"));

const PurchaseModule = lazy(
  () => import("./modules/customer-admin/manage-modules/PurchaseModule")
);
const SetPassword = lazy(() => import("./modules/onboard/SetPassword"));
const InstituteList = lazy(
  () => import("./modules/customer-admin/institutes/InstituteList")
);
const ModuleList = lazy(
  () => import("./modules/customer-admin/manage-modules/ModuleList")
);
const AddInstitute = lazy(
  () => import("./modules/customer-admin/institutes/AddInstitute")
);
const RolesList = lazy(
  () => import("./modules/customer-admin/roles/RolesList")
);
const AddRole = lazy(() => import("./modules/customer-admin/roles/AddRole"));
const AddUser = lazy(() => import("./modules/customer-admin/users/AddUser"));
const UserList = lazy(() => import("./modules/customer-admin/users/UserList"));
const Configurations = lazy(
  () => import("./modules/customer-admin/configurations")
);
const Course = lazy(
  () => import("./modules/customer-admin/configurations/Course")
);
const Division = lazy(
  () => import("./modules/customer-admin/configurations/Division")
);
const Class = lazy(
  () => import("./modules/customer-admin/configurations/Class")
);
const ClassDivision = lazy(
  () => import("./modules/institute-admin/admission/ClassDivision")
);
const SubjectMaster = lazy(
  () => import("./modules/customer-admin/configurations/SubjectMaster")
);
const FinancialYear = lazy(
  () => import("./modules/customer-admin/configurations/FinancialYear")
);
const AcademicYear = lazy(
  () => import("./modules/customer-admin/configurations/AcademicYear")
);
const Religion = lazy(
  () => import("./modules/institute-admin/masters/Religion")
);
const Masters = lazy(() => import("./modules/institute-admin/masters"));
const Community = lazy(
  () => import("./modules/institute-admin/masters/Community")
);
const Category = lazy(
  () => import("./modules/institute-admin/masters/Category")
);
const Nationality = lazy(
  () => import("./modules/institute-admin/masters/Nationality")
);
const State = lazy(() => import("./modules/institute-admin/masters/State"));
const District = lazy(
  () => import("./modules/institute-admin/masters/District")
);
const Language = lazy(
  () => import("./modules/institute-admin/masters/Languages")
);
const AuxiliarySubject = lazy(
  () => import("./modules/institute-admin/masters/AuxiliarySubjects")
);
const MotherTongue = lazy(
  () => import("./modules/institute-admin/masters/MotherTongue")
);
const HouseGroup = lazy(
  () => import("./modules/institute-admin/masters/HouseGroup")
);
const Profession = lazy(
  () => import("./modules/institute-admin/masters/Profession")
);
const Documents = lazy(
  () => import("./modules/institute-admin/masters/Documents")
);
const RuleGroup = lazy(
  () => import("./modules/institute-admin/fee/FeeRuleGroup")
);
const FeeRule = lazy(() => import("./modules/institute-admin/fee/FeeRule"));

const Admission = lazy(
  () => import("./modules/institute-admin/admission/Admission")
);
const AdmissionList = lazy(
  () => import("./modules/institute-admin/admission/AdmissionList")
);
const AdmissionDetail = lazy(
  () => import("./modules/institute-admin/admission/AdmissionDetail")
);
const FeeConfiguration = lazy(
  () => import("./modules/institute-admin/fee-configuration")
);
const ChangeFeeRule = lazy(
  () => import("./modules/institute-admin/fee-configuration/ChangeFeeRule")
);

const FeeCollection = lazy(
  () => import("./modules/institute-admin/fee-collection")
);

const ConcessionApproval = lazy(
  () => import("./modules/institute-admin/fee/concession/ConcessionApproval")
);
const LeaveTypes = lazy(
  () => import("./modules/institute-admin/attendance/LeaveTypes")
);

const OffDays = lazy(
  () => import("./modules/institute-admin/attendance/OffDays")
);
const AttHours = lazy(
  () => import("./modules/institute-admin/attendance/AttHours")
);

const LateEarlyEntry = lazy(
  () => import("./modules/institute-admin/attendance/LateEarlyEntry")
);

const LeaveRequest = lazy(
  () => import("./modules/institute-admin/attendance/LeaveRequest")
);

const ConcessionReport = lazy(
  () => import("./modules/institute-admin/fee/reports/ConcessionReport")
);
const StudentGroup = lazy(
  () => import("./modules/institute-admin/attendance/StudentGroup")
);
const BusDocumentMaster = lazy(
  () => import("./modules/institute-admin/transportation/BusDocumentMaster")
);
const Bus = lazy(() => import("./modules/institute-admin/transportation/Bus"));
const BusPoints = lazy(
  () => import("./modules/institute-admin/transportation/BusPoints")
);
const BusRoute = lazy(
  () => import("./modules/institute-admin/transportation/BusRoute")
);
const BusFeeConfig = lazy(
  () => import("./modules/institute-admin/transportation/BusFeeConfig")
);

const AddToBus = lazy(
  () => import("./modules/institute-admin/transportation/AddToBus")
);
const TransportationCollectionReport = lazy(
  () =>
    import(
      "./modules/institute-admin/transportation/reports/TransportationCollectionReport"
    )
);
const StudentsReport = lazy(
  () =>
    import("./modules/institute-admin/transportation/reports/StudentsReport")
);

const TransportationFeeSummary = lazy(
  () =>
    import("./modules/institute-admin/transportation/TransportationFeeSummary")
);
const TransportationDocumentsReport = lazy(
  () =>
    import(
      "./modules/institute-admin/transportation/TransportationDocumentsReport"
    )
);
const AttendanceSheet = lazy(
  () =>
    import("./modules/institute-admin/transportation/reports/AttendanceSheet")
);

const StudentWiseTransportationFee = lazy(
  () =>
    import(
      "./modules/institute-admin/transportation/reports/StudentWiseTransportationFee"
    )
);

const TakeAttendance = lazy(
  () => import("./modules/institute-admin/attendance/TakeAttendance")
);
const AttendanceStatusReport = lazy(
  () =>
    import(
      "./modules/institute-admin/attendance/reports/AttendanceStatusReport"
    )
);

const MonthlyAttendanceReport = lazy(
  () =>
    import(
      "./modules/institute-admin/attendance/reports/MonthlyAttendanceReport"
    )
);

const AttendanceSummaryReport = lazy(
  () =>
    import(
      "./modules/institute-admin/attendance/reports/AttendanceSummaryReport"
    )
);

const StudentWiseAttendanceReport = lazy(
  () =>
    import(
      "./modules/institute-admin/attendance/reports/StudentWiseAttendanceReport"
    )
);

const DailySummaryReport = lazy(
  () =>
    import("./modules/institute-admin/attendance/reports/DailySummaryReport")
);

const DueReport = lazy(
  () => import("./modules/institute-admin/fee/reports/DueReport")
);
const CollectionReport = lazy(
  () => import("./modules/institute-admin/fee/reports/CollectionReport")
);
const InvoiceReport = lazy(
  () => import("./modules/institute-admin/fee/reports/InvoiceReport")
);
const MyCollection = lazy(
  () => import("./modules/institute-admin/fee/reports/MyCollection")
);
const AdvanceReport = lazy(
  () => import("./modules/institute-admin/fee/reports/AdvanceReport")
);
const FeeSummary = lazy(
  () => import("./modules/institute-admin/fee/reports/FeeSummaryReport")
);
const CollectionSummary = lazy(
  () => import("./modules/institute-admin/fee/reports/CollectionSummary")
);
const InstallmentSummary = lazy(
  () => import("./modules/institute-admin/fee/reports/InstallmentSummary")
);
const StudentFeeSummary = lazy(
  () => import("./modules/institute-admin/fee/reports/StudentFeeSummary")
);
const Bundles = lazy(() => import("./modules/institute-admin/store/Bundles"));
const WareHouses = lazy(
  () => import("./modules/institute-admin/store/WareHouses")
);
const Suppliers = lazy(
  () => import("./modules/institute-admin/store/Suppliers")
);
const ItemGroup = lazy(
  () => import("./modules/institute-admin/store/ItemGroup")
);
const Items = lazy(() => import("./modules/institute-admin/store/Items"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Main = () => {
  const _authContext = getUserContext();

  const [authContext, setAuthContext] = useState(
    _authContext && parse(_authContext)
  );

  return (
    <AuthContext.Provider value={{ authContext, setAuthContext }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              {/* static site route */}
              <Route path="/" element={<LandingPageLayout />}>
                <Route path="/features" element={<span>Features</span>} />
              </Route>
              <Route
                path="/"
                element={<AuthMiddleware component={<AdminLayout />} />}
              >
                <Route path="/home" element={<Dashboard />} />
                <Route path="/modules" element={<ModuleList />} />
                <Route path="/purchase-module" element={<PurchaseModule />} />
                <Route
                  path="/institutes/add-institute"
                  element={<AddInstitute />}
                />
                <Route path="/institutes" element={<InstituteList />} />
                <Route path="/roles" element={<RolesList />} />
                <Route path="/roles/add-role" element={<AddRole />} />
                <Route path="/users" element={<UserList />} />
                <Route path="/add-user" element={<AddUser />} />
                <Route path="/configurations" element={<Configurations />}>
                  <Route path="academic-year" element={<AcademicYear />} />
                  <Route path="financial-year" element={<FinancialYear />} />
                  <Route path="class" element={<Class />} />
                  <Route path="course" element={<Course />} />
                  <Route path="division" element={<Division />} />
                  <Route path="subject-master" element={<SubjectMaster />} />
                  <Route index element={<AcademicYear />} />
                </Route>

                <Route path="/masters" element={<Masters />}>
                  <Route path="religion" element={<Religion />} />
                  <Route path="community" element={<Community />} />
                  <Route path="category" element={<Category />} />
                  <Route index element={<Religion />} />

                  <Route path="nationality" element={<Nationality />} />
                  <Route path="state" element={<State />} />
                  <Route path="district" element={<District />} />
                  <Route path="Language" element={<Language />} />
                  <Route
                    path="auxiliary-subject"
                    element={<AuxiliarySubject />}
                  />
                  <Route path="mother-tongue" element={<MotherTongue />} />
                  <Route path="house-group" element={<HouseGroup />} />
                  <Route path="profession" element={<Profession />} />
                  <Route path="document" element={<Documents />} />
                </Route>

                {/* # Institute Admin */}

                <Route path="/admission" element={<Outlet />}>
                  <Route path=":id" element={<AdmissionDetail />} />
                  <Route path="create" element={<Admission />} />
                  <Route path="" element={<AdmissionList />} />
                  <Route index element={<AdmissionList />} />
                  <Route path="class-division" element={<ClassDivision />} />
                </Route>
                <Route path="/finance" element={<Outlet />}>
                  <Route path="finance-ledger" element={<FinanceLedger />} />
                  <Route path="ledger-group" element={<LedgerGroup />} />
                  <Route path="bank-accounts" element={<BankAccount />} />
                  <Route
                    path="payment-voucher"
                    element={<PaymentOrReceipt type={VoucherType.payment} />}
                  />
                  <Route
                    path="receipt-voucher"
                    element={<PaymentOrReceipt type={VoucherType.receipt} />}
                  />
                  <Route path="journal-voucher" element={<JournalVoucher />} />
                  <Route
                    path="deposit"
                    element={<DepositOrWithdrawal type={VoucherType.deposit} />}
                  />
                  <Route
                    path="withdrawal"
                    element={
                      <DepositOrWithdrawal type={VoucherType.withdrawal} />
                    }
                  />
                  <Route path="pdc" element={<Pdc />} />
                  <Route path="opening-balance" element={<OpeningBalance />} />
                  <Route path="reports">
                    <Route path="day-book" element={<DayBook />} />
                    <Route path="books" element={<Books />} />
                    <Route
                      path="profile-and-loss-account"
                      element={
                        <ProfitAndLossAccount
                          type={ProfitAndLossAccountType.profitAndLoss}
                        />
                      }
                    />
                    <Route
                      path="income-statement"
                      element={
                        <ProfitAndLossAccount
                          type={ProfitAndLossAccountType.incomeStatement}
                        />
                      }
                    />
                    <Route path="trial-balance" element={<TrialBalance />} />
                    <Route
                      path="seasonal-trial-balance"
                      element={<SeasonalTrialBalance />}
                    />
                    <Route
                      path="monthly-trial-balance"
                      element={<MonthlyTrialBalance />}
                    />
                    <Route
                      path="receipts-and-payments"
                      element={<ReceiptAndPayment />}
                    />
                    <Route path="daily-closing" element={<DailyClosing />} />
                    <Route path="balance-sheet" element={<BalanceSheet />} />
                    <Route index element={<DayBook />} />
                  </Route>
                  <Route index element={<FinanceLedger />} />
                </Route>

                <Route path="/fee" element={<FeeLayout />}>
                  <Route
                    path="fee-configuration"
                    element={<FeeConfiguration />}
                  />
                  <Route path="change-fee-rule" element={<ChangeFeeRule />} />
                  <Route index element={<FeeConfiguration />} />
                  {/* fee */}
                  <Route
                    path="installment-Group"
                    element={<InstallmentGroup />}
                  />
                  <Route
                    path="installment-master"
                    element={<InstallmentMaster />}
                  />
                  <Route path="fine-engine" element={<FineEngine />} />
                  <Route path="fee-master" element={<FeeMaster />} />
                  <Route path="fee-Banks" element={<FeeBanks />} />
                  <Route path="rule-group" element={<RuleGroup />} />
                  <Route path="fee-rule" element={<FeeRule />} />
                  <Route path="bill-book" element={<BillBook />} />
                  <Route path="fee-collection" element={<FeeCollection />} />
                  <Route path="fee-settings" element={<FeeSettings />} />
                  <Route index element={<InstallmentGroup />} />
                </Route>

                {/* Attendance */}

                <Route path="/attendance" element={<Outlet />}>
                  <Route path="leave-type" element={<LeaveTypes />} />
                  <Route path="off-days" element={<OffDays />} />
                  <Route path="att-hours" element={<AttHours />} />
                  <Route path="leave-request" element={<LeaveRequest />} />
                  <Route path="student-group" element={<StudentGroup />} />
                  <Route path="take-attendance" element={<TakeAttendance />} />
                  <Route path="late-early-entry" element={<LateEarlyEntry />} />
                  <Route path="reports">
                    <Route
                      path="attendance-status"
                      element={<AttendanceStatusReport />}
                    />
                    <Route
                      path="monthly-attendance"
                      element={<MonthlyAttendanceReport />}
                    />
                    <Route
                      path="attendance-summary"
                      element={<AttendanceSummaryReport />}
                    />
                    <Route
                      path="student-wise-attendance"
                      element={<StudentWiseAttendanceReport />}
                    />
                    <Route
                      path="daily-summary"
                      element={<DailySummaryReport />}
                    />
                    <Route index element={<AttendanceStatusReport />} />
                  </Route>
                  <Route index element={<TakeAttendance />} />
                </Route>
                {/* {transportation} */}
                <Route path="/transportation" element={<Outlet />}>
                  <Route path="bus" element={<Bus />} />
                  <Route
                    path="bus-document-master"
                    element={<BusDocumentMaster />}
                  />
                  <Route path="bus-points" element={<BusPoints />} />
                  <Route path="bus-route" element={<BusRoute />} />
                  <Route path="bus-fee-config" element={<BusFeeConfig />} />
                  <Route path="add-to-bus" element={<AddToBus />} />

                  <Route index element={<BusDocumentMaster />} />
                </Route>
                {/* {transportation/report} */}
                <Route path="transportation/reports" element={<Outlet />}>
                  <Route path="students-report" element={<StudentsReport />} />
                  <Route
                    path="attendance-sheet"
                    element={<AttendanceSheet />}
                  />
                  <Route
                    path="transportation-collection-report"
                    element={<TransportationCollectionReport />}
                  />
                  <Route index element={<TransportationCollectionReport />} />
                  <Route
                    path="transportation-fee-summary"
                    element={<TransportationFeeSummary />}
                  />
                  <Route
                    path="transportation-document-report"
                    element={<TransportationDocumentsReport />}
                  />
                  <Route
                    path="student-wise-fee"
                    element={<StudentWiseTransportationFee />}
                  />
                  <Route index element={<BusDocumentMaster />} />
                </Route>

                {/* {Store} */}

                <Route path="/store" element={<Outlet />}>
                  <Route path="bundles" element={<Bundles />} />
                  <Route path="wareHouses" element={<WareHouses />} />
                  <Route path="suppliers" element={<Suppliers />} />
                  <Route path="item-group" element={<ItemGroup />} />
                  <Route path="items" element={<Items />} />
                  <Route index element={<Bundles />} />
                </Route>

                <Route path="/fee/concession" element={<Outlet />}>
                  <Route path="request" element={<ConcessionRequest />} />
                  <Route path="approve/:id" element={<ConcessionApproval />} />
                  <Route path="*" element={<div>No Request found</div>} />

                  <Route index element={<ConcessionRequest />} />
                </Route>
                <Route path="fee/reports" element={<Outlet />}>
                  <Route path="due-report" element={<DueReport />} />
                  <Route path="advance-report" element={<AdvanceReport />} />
                  <Route
                    path="collection-report"
                    element={<CollectionReport />}
                  />
                  <Route path="invoice-report" element={<InvoiceReport />} />
                  <Route path="my-collection" element={<MyCollection />} />
                  <Route path="fee-summary" element={<FeeSummary />} />
                  <Route
                    path="collection-summary"
                    element={<CollectionSummary />}
                  />
                  <Route
                    path="installment-summary"
                    element={<InstallmentSummary />}
                  />
                  <Route
                    path="student-fee-summary"
                    element={<StudentFeeSummary />}
                  />
                  <Route
                    path="concession-report"
                    element={<ConcessionReport />}
                  />
                  <Route index element={<DueReport />} />
                </Route>
              </Route>

              {/* App level route */}
              <Route
                path="/"
                element={<AuthMiddleware component={<OnboardLayout />} />}
              >
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/set-password" element={<SetPassword />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
};
root.render(
  <StrictMode>
    <Main />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
