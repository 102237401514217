import { Outlet } from "react-router-dom";

export function OnboardLayout() {
  return (
    <div className="grid grid-cols-2 gap-4 min-h-screen">
      <div className="bg-teal-500 grow"></div>
      <div className="flex items-center grow text-sm font-medium">
        <div className="m-auto w-96">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
