import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Link, useNavigate } from "react-router-dom";
import { post } from "../../data/apiRequest";
import { SignupSchema } from "./helper";
import { RegisterForm } from "./RegisterForm";
import { showError, showSuccess } from "../../components/Toast";

export function Register() {
  const navigate = useNavigate();
  const signUp = useMutation(
    (values: SignupSchema) => {
      return post("Signup", values);
    },
    {
      onSuccess: (data: AxiosResponse) => {
        showSuccess("Signup Success");
        setTimeout(() => {
          navigate(`/set-password${new URL(data.data.url).search}`);
        }, 2000);
      },
      onError: (e) => {
        showError("Something went wrong");
      },
    }
  );
  const handleSubmit = (data: SignupSchema) => {
    signUp.mutate(data);
  };

  return (
    <>
      <h3 className="text-3xl mb-6 font-semibold">Register</h3>
      <RegisterForm
        isSubmitting={signUp.isLoading}
        handleSubmit={handleSubmit}
      />
      Already have an account?{" "}
      <Link to="/login" className="text-teal-500">
        Log In
      </Link>
    </>
  );
}
