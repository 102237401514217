import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import "./styles/tailwind-output.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material";
import { teal } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: teal[500],
    },
  },
  typography: {
    fontFamily: "'IBM Plex Sans', sans-serif",
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Outlet />
        <Toaster />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
