import TextField from "@mui/material/TextField";
import React from "react";

export function AppInput({
  name = "",
  type = "text",
  placeholder = "",
  required,
  value,
  onChange,
  onKeyDown,
  id = "",
}: {
  name: string;
  type?:
    | "text"
    | "number"
    | "password"
    | "email"
    | "password"
    | "file"
    | "time"
    | "date";
  placeholder?: string;
  required?: boolean;
  value?: string | number | undefined;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
  id?: number | string;
}) {
  return (
    <TextField
      sx={{
        "&": {
          width: "100%",
        },
      }}
      size="small"
      type={type}
      placeholder={placeholder}
      required={required}
      name={name}
      value={value}
      onChange={onChange}
      id={String(id || name)}
      variant="outlined"
      onKeyDown={onKeyDown}
    />
  );
}
