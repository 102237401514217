import axios, { AxiosRequestConfig } from "axios";
import { getUserContext } from "../auth/auth-context";
import { selectActiveInstitute } from "../modules/customer-admin/util";
import { parse } from "../utils/json-util";
// const API_BASE_URL = 'https://192.168.1.25:5001' // 5G
// export const API_BASE_URL = "http://192.168.1.47:5000"; // LLP
export const API_BASE_URL = "http://api.beta.schola.in"; // LLP
// const API_BASE_URL =  'https://localhost:5001'; // local
// const API_BASE_URL = 'https://192.168.0.122:5001' // LLP
// const API_BASE_URL = 'https://localhost:5011'; // local

export const apiRequest = axios.create({
  baseURL: `${API_BASE_URL}/api/`,
  timeout: 30000,
  headers: {
    "x-app": "schola-react-web-app",
  },
});

apiRequest.interceptors.request.use((config) => {
  const userContext = getUserContext();
  const { token, academicYear, customerId, financialYear } = parse(userContext);
  const institute = selectActiveInstitute();

  if (config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.instituteId = institute?.id;
    config.headers.AcademicYearId = academicYear?.id;
    config.headers.customerId = customerId;
    config.headers.financialYearId = financialYear?.id;
  }
  return config;
});

export const get = (url: string) => apiRequest.get(url);
export const post = (url: string, data: unknown) => apiRequest.post(url, data);
export const put = (url: string, data: unknown) => apiRequest.put(url, data);
export const remove = (
  url: string,
  data?: AxiosRequestConfig<any> | undefined
) => apiRequest.delete(url, data);
