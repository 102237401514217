import { useFormik } from "formik";
import { AppInput } from "../../components/AppInput";
import { Button } from "../../components/Button";
import { ErrorMessage } from "../../components/ErrorMessage";
import { AppLabel } from "../../components/form/AppLabel";
import { validateLoginForm } from "./helper";

export function LoginForm({
  handleSubmit,
  isSubmitting,
}: {
  handleSubmit: any;
  isSubmitting: boolean;
}) {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: true,
    },
    validate: validateLoginForm,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const { values, handleChange } = formik;

  return (
    <form onSubmit={formik.handleSubmit} className="mb-5">
      <div>
        <AppLabel name="Username" classNames={" mb-1 block"}>
          <AppInput name="email" value={values.email} onChange={handleChange} />
          {formik.errors.email && formik.touched.email && (
            <ErrorMessage text={formik.errors.email} />
          )}
        </AppLabel>
      </div>

      <div>
        <AppLabel name="Password" classNames={" mb-1 block mt-4"}>
          <AppInput name="password" type="password" onChange={handleChange} />
          {formik.errors.password && formik.touched.password && (
            <ErrorMessage text={formik.errors.password} />
          )}
        </AppLabel>
      </div>
      <div className="flex justify-between text-xs my-3 align-center">
        <label className="flex align-center">
          <input
            type="checkbox"
            name="rememberMe"
            id=""
            className="mr-1 accent-teal-500"
            checked={values.rememberMe}
            onChange={handleChange}
          />{" "}
          Remember me
        </label>
        <a href="forgot-password" className="text-teal-500">
          Forgot Password ?
        </a>
      </div>

      <Button type="submit" text="Login" disabled={isSubmitting} />
    </form>
  );
}
