import {
  ProfitAndLoss,
  ProfitAndLossDetail,
  useProfitAndLossAccount,
} from "./finance-report-data";
import { useFormik } from "formik";
import { tableClass } from "../../../../common-style-class";
import { AppInput } from "../../../../components/AppInput";
import { AppLabel } from "../../../../components/form/AppLabel";
import { formatForDatePicker } from "../../../../utils/date-util";
import { PageHead } from "../../../customer-admin/components/PageHead";
import { sumBy } from "lodash";
import { Fragment } from "react";
import { toFixedAmount } from "../../../../utils/number-util";
import { getUserContext } from "../../../../auth/auth-context";
import { parse } from "../../../../utils/json-util";
import { FinancialYear } from "../FinancialYear";
import { NegativeMarker } from "./NegativeMarker";

export enum ProfitAndLossAccountType {
  profitAndLoss = "profile-and-loss-account",
  incomeStatement = "income-statement",
}

export default function ProfitAndLossAccount({
  type,
}: {
  type: ProfitAndLossAccountType;
}) {
  const userContext = getUserContext();
  const { financialYear } = parse(userContext);

  const formik = useFormik({
    initialValues: {
      fromDate: new Date(financialYear.startingDate),
      toDate: new Date(financialYear.endingDate),
    },
    onSubmit: (values) => {},
  });

  const { values, handleChange } = formik;

  const { data } = useProfitAndLossAccount(
    formatForDatePicker(values.fromDate),
    formatForDatePicker(values.toDate)
  );

  const expenses = data?.filter((x) => x.lgType === "E") ?? [];
  const incomes = data?.filter((x) => x.lgType === "I") ?? [];

  const expensesDetails = expenses.reduce(
    (acc: ProfitAndLossDetail[], current: ProfitAndLoss) => {
      const existing = [...acc, ...current.details];
      return existing;
    },
    []
  );

  const expenseTotal = sumBy(expensesDetails, "amount");

  const incomeDetails = incomes.reduce(
    (acc: ProfitAndLossDetail[], current: ProfitAndLoss) => {
      const existing = [...acc, ...current.details];
      return existing;
    },
    []
  );

  const incomeTotal = sumBy(incomeDetails, "amount");
  const profit = incomeTotal - expenseTotal;

  return (
    <div>
      <div className="flex justify-between">
        <PageHead
          title={
            type === ProfitAndLossAccountType.profitAndLoss
              ? "Profit & Loss Account"
              : "Income Statement"
          }
        />
        <FinancialYear />
      </div>

      <div className="flex gap-3 mb-5">
        <div>
          <AppLabel name="From Date" classNames={"mb-1 block mt-2"}>
            <AppInput
              value={formatForDatePicker(values.fromDate)}
              name="fromDate"
              placeholder=""
              onChange={handleChange}
              type="date"
            />
          </AppLabel>
        </div>
        <div>
          <AppLabel name="To Date" classNames={"mb-1 block mt-2"}>
            <AppInput
              value={formatForDatePicker(values.toDate)}
              name="toDate"
              placeholder=""
              onChange={handleChange}
              type="date"
            />
          </AppLabel>
        </div>
      </div>

      {type === ProfitAndLossAccountType.profitAndLoss ? (
        <div className="flex bg-white p-3 rounded-sm shadow-sm">
          <div className="grow mr-2" hidden={expenses.length === 0}>
            <table className="w-full">
              <thead>
                <tr className="bg-slate-50">
                  <th className={tableClass.thClass}>Particulars</th>
                  <th className={tableClass.thClass}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {expenses.map((x) => (
                  <Fragment key={x.ledgerGroupId}>
                    <tr>
                      <td className={tableClass.tdClass}>
                        <strong>{x.groupName}</strong>
                      </td>
                      <td className={tableClass.tdClass}>
                        <strong>
                          <NegativeMarker amount={sumBy(x.details, "amount")} />
                        </strong>
                      </td>
                    </tr>
                    {x.details.map((item) => (
                      <tr key={item.ledgerId}>
                        <td className={`${tableClass.tdClass} pl-6`}>
                          {item.ledger}
                        </td>
                        <td className={tableClass.tdClass}>
                          <NegativeMarker amount={item.amount} /> {item.crOrDr}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className="grow ml-2" hidden={incomes.length === 0}>
            <table className="w-full">
              <thead>
                <tr className="bg-slate-50">
                  <th className={tableClass.thClass}>Particulars</th>
                  <th className={tableClass.thClass}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {incomes.map((x) => (
                  <Fragment key={x.ledgerGroupId}>
                    <tr>
                      <td className={tableClass.tdClass}>
                        <strong>{x.groupName}</strong>
                      </td>
                      <td className={tableClass.tdClass}>
                        <strong>
                          <NegativeMarker amount={sumBy(x.details, "amount")} />
                        </strong>
                      </td>
                    </tr>
                    {x.details.map((item) => (
                      <tr key={item.ledgerId}>
                        <td className={`${tableClass.tdClass} pl-6`}>
                          {item.ledger}
                        </td>
                        <td className={tableClass.tdClass}>
                          <NegativeMarker amount={item.amount} /> {item.crOrDr}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="bg-white p-3 rounded-md shadow-sm">
          <table className="w-full">
            <thead>
              <tr className="bg-slate-50">
                <th className={tableClass.thClass}>Particulars</th>
                <th className={tableClass.thClass}>Amount</th>
                <th className={tableClass.thClass}>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={3} className={tableClass.tdClass}>
                  <strong>Revenues</strong>
                </td>
              </tr>
              {incomes.map((x) => (
                <Fragment key={x.ledgerGroupId}>
                  {x.details.map((item) => (
                    <tr key={item.ledgerId}>
                      <td className={`${tableClass.tdClass} pl-6`}>
                        {item.ledger}
                      </td>
                      <td className={`${tableClass.tdClass} text-right`}>
                        {item.crOrDr === "Dr" &&
                          `${toFixedAmount(Math.abs(item.amount))} Dr`}
                      </td>
                      <td className={`${tableClass.tdClass} text-right`}>
                        {item.crOrDr === "Cr" &&
                          `${toFixedAmount(Math.abs(item.amount))} Cr`}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
              <tr>
                <td colSpan={3} className={tableClass.tdClass}>
                  <strong>Expenses</strong>
                </td>
              </tr>
              {expenses.map((x) => (
                <Fragment key={x.ledgerGroupId}>
                  {x.details.map((item) => (
                    <tr key={item.ledgerId}>
                      <td className={`${tableClass.tdClass} pl-6`}>
                        {item.ledger}
                      </td>
                      <td className={`${tableClass.tdClass} text-right`}>
                        {item.crOrDr === "Dr" &&
                          `${toFixedAmount(Math.abs(item.amount))} Dr`}
                      </td>
                      <td className={`${tableClass.tdClass} text-right`}>
                        {item.crOrDr === "Cr" &&
                          `${toFixedAmount(Math.abs(item.amount))} Cr`}
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
              <tr>
                <td className={tableClass.tdClass}>Total Revenues</td>
                <td className={`${tableClass.tdClass} text-right`} colSpan={2}>
                  <strong>
                    <NegativeMarker amount={incomeTotal} />
                  </strong>
                </td>
              </tr>
              <tr>
                <td className={tableClass.tdClass}>Total Expenses</td>
                <td className={`${tableClass.tdClass} text-right`} colSpan={2}>
                  <strong>{toFixedAmount(expenseTotal)}</strong>
                </td>
              </tr>
              <tr className={profit >= 0 ? "bg-green-100" : "bg-red-100"}>
                <td className={tableClass.tdClass}>
                  Net {profit >= 0 ? "Profit" : "Loss"} (Revenues - Expenses)
                </td>
                <td className={`${tableClass.tdClass} text-right`} colSpan={2}>
                  <strong>{toFixedAmount(Math.abs(profit))}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
