import isArray from "lodash/isArray";

export function FormLabel({
  name,
  classNames = "",
  htmlFor = "",
  children,
}: {
  name: string;
  children?: React.ReactNode;
  htmlFor?: string;
  classNames?: string[] | string;
}) {
  const _classes: string = (
    isArray(classNames) ? (classNames as string[]).join(" ") : classNames
  ) as string;
  return (
    <>
      <label
        htmlFor={htmlFor || name}
        className={`cursor-pointer text-sm ${_classes}`}
      >
        {name}
      </label>
      {children}
    </>
  );
}
