import { useQuery } from "@tanstack/react-query";
import { get } from "../../../../data/apiRequest";

export declare module DayBookType {
  export interface Detail {
    ledger: string;
    code: string;
    ledgerId: number;
    crAmount: number;
    drAmount: number;
    narration: string;
  }

  export interface RootObject {
    id: number;
    date: string;
    type: string;
    voucherNo: string;
    reason: string;
    referenceNo: string;
    cancelled: boolean;
    transactionType: string;
    createdBy: string;
    details: Detail[];
  }
}

export function useDayBook(fromDate: string, toDate: string) {
  return useQuery<DayBookType.RootObject[]>(["DayBook", fromDate, toDate], () =>
    get(`FinanceReport/DayBook?dateFrom=${fromDate}&dateTo=${toDate}`).then(
      (res) => res.data
    )
  );
}

export declare module BooksType {
  export interface Detail {
    ledger: string;
    code: string;
    ledgerId: number;
    crAmount: number;
    drAmount: number;
    narration: string;
  }

  export interface Book {
    id: number;
    date: Date;
    type: string;
    voucherNo: string;
    reason: string;
    referenceNo: string;
    cancelled: boolean;
    transactionType: string;
    createdBy: string;
    details: Detail[];
  }

  export interface RootObject {
    openingBalance: number;
    drOrCr: string;
    crTotal: number;
    drTotal: number;
    obDrOrCr: string;
    closingBalance: number;
    clDrOrCr: string;
    data: Book[];
  }
}

export function useBooks(ledgerId: number, fromDate: string, toDate: string) {
  return useQuery<BooksType.RootObject>(
    ["Book", ledgerId, fromDate, toDate],
    () =>
      get(
        `FinanceReport/Book?ledgerId=${ledgerId}&dateFrom=${fromDate}&dateTo=${toDate}`
      ).then((res) => res.data),
    {
      enabled: !!ledgerId,
    }
  );
}

export interface ProfitAndLoss {
  ledgerGroupId: number;
  groupName: string;
  lgType: string;
  details: ProfitAndLossDetail[];
}

export interface ProfitAndLossDetail {
  ledger: string;
  ledgerId: number;
  amount: number;
  crOrDr: string;
}

export function useProfitAndLossAccount(fromDate: string, toDate: string) {
  return useQuery<ProfitAndLoss[]>(
    ["IncomeAndExpenditure", fromDate, toDate],
    () =>
      get(
        `FinanceReport/IncomeAndExpenditure?dateFrom=${fromDate}&dateTo=${toDate}`
      ).then((res) => res.data)
  );
}
