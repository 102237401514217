export function PageHead({
  title = "",
  rightContent = null,
}: {
  title: string;
  rightContent?: any;
}) {
  return (
    <div className="my-2 flex items-center justify-between">
      <h1 className="text-2xl text-black/600 font-medium">{title}</h1>
      {rightContent}
    </div>
  );
}
