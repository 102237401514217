import React, { useEffect } from "react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserContext } from "../auth/auth-context";
import { AuthContext } from "../context/AuthContext";
import { parse } from "../utils/json-util";

export function AuthMiddleware({
  component,
}: {
  component: React.ReactElement;
}) {
  const { authContext } = useContext(AuthContext);
  const userContext = getUserContext();
  const token = userContext
    ? parse(userContext)?.token
    : authContext?.data?.token;
  const nav = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // if user is already logged in and they land on login page again. redirect them to home page
    if (token && location?.pathname === "/login") {
      nav("/home");
    }

    // if there is no token and they try to land on guarded pages, redirect them back to login
    if (
      !token &&
      location?.pathname !== "/login" &&
      location?.pathname !== "/register" &&
      !location?.pathname?.startsWith("/Set-Password")
    ) {
      nav("/login");
    }
  }, [token, nav, location?.pathname]);

  // if user is logged in and landed on login page, return null, redirection already handled in effefct,
  // otherwise use see the login component for a second, then redirect.
  // if the use is logged in and not in login page return the passed component as it is.
  return token && location?.pathname === "login" ? null : component;
}
