import format from "date-fns/format";

export function formatDate(
  date: string | Date = new Date().toDateString(),
  _format = "dd/MM/yyyy"
) {
  return format(new Date(date), _format);
}

export function formatForDatePicker(
  date: string | Date = new Date().toDateString(),
  _format = "yyyy-MM-dd"
) {
  if (!date) {
    // empty string | invalid date range case
    return format(new Date(), _format);
  }
  return format(new Date(date), _format);
}
