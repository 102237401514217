import { Link } from "react-router-dom";
import { PageHead } from "../components/PageHead";

// add to level routes here

const tiles = [
  {
    link: "/modules",
    text: "Modules",
  },
  {
    link: "/institutes",
    text: "Institutes",
  },
  {
    link: "/fee",
    text: "Fee",
  },
  {
    link: "/roles",
    text: "Roles",
  },
  {
    link: "/users",
    text: "Users",
  },
  {
    link: "/configurations",
    text: "Configurations",
  },
  {
    link: "/masters",
    text: "Masters",
  },
  {
    link: "/admission",
    text: "Admission",
  },
  {
    link: "/attendance",
    text: "Attendance",
  },
  {
    link: "/transportation",
    text: "Transportation",
  },
  {
    link: "/finance",
    text: "Finance",
  },
  {
    link: "/store",
    text: "Store",
  },
];

export default function Dashboard() {
  return (
    <div>
      <PageHead title="Dashboard" />
      <div className="flex">
        <div className="my-4 flex flex-wrap">
          {tiles.map((x: any) => (
            <Tile key={x.link} text={x.text} link={x.link} />
          ))}
        </div>
      </div>
    </div>
  );
}

const Tile = ({ link, text }: { link: string; text: string }) => {
  return (
    <Link
      className="px-10 border-b py-5 mx-2 w-64 text-xl text-center cursor-pointer bg-white rounded-md shadow-sm mb-4"
      to={link}
    >
      {text}
    </Link>
  );
};
