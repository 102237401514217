import { Link, Outlet } from "react-router-dom";
import logo from "../../assets/images/schola-logo.svg";

export function LandingPageLayout() {
  return (
    <div className=" bg-gray-100 px-5 py-2">
      <div className="flex justify-between items-center">
        <div>
          <img src={logo} alt="" />
        </div>
        <div>
          <Link to={"/features"} className="mr-6">
            Features
          </Link>
          <Link to={"/features"} className="mr-6">
            Solutions
          </Link>
          <Link to={"/help"}>Help Center</Link>
        </div>
        <div className="font-semibold text-sm">
          <Link to={"/register"} className="mr-3 text-teal-700">
            Register Now
          </Link>
          <span className="mr-3 text-teal-700">|</span>
          <Link className="text-teal-700" to={"/login"}>
            Login
          </Link>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
