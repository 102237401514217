export type SignupSchema = {
  name?: string;
  email?: string;
};

export type LoginSchema = {
  email?: string;
  password?: string;
  rememberMe?: boolean;
};

export type SetPasswordSchema = {
  password?: string;
  confirmPassword?: string;
  key?: string | null;
};

export const validateSignUpForm = (values: SignupSchema) => {
  const errors: SignupSchema = {};

  if (!values.name) {
    errors.name = "Name is Required";
  }
  if (!values.email) {
    errors.email = "Email ID is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export const validateLoginForm = (values: LoginSchema) => {
  const errors: LoginSchema = {};

  if (!values.email) {
    errors.email = "Username is Required";
  }
  if (!values.password) {
    errors.password = "Password is Required";
  }
  return errors;
};

export const validateSetPasswordForm = (values: SetPasswordSchema) => {
  const errors: SetPasswordSchema = {};

  if (!values.password) {
    errors.password = "Password is required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  }
  if (values.password !== values.confirmPassword) {
    errors.password = "Password mismatch";
    errors.confirmPassword = "Password mismatch";
  }
  return errors;
};
