import { parse } from "../../utils/json-util";

export const SCHOLA_ACTIVE_INSTITUTE = "schola_active_institute";
export function selectActiveInstitute() {
  const c =
    sessionStorage.getItem(SCHOLA_ACTIVE_INSTITUTE) ||
    localStorage.getItem(SCHOLA_ACTIVE_INSTITUTE);
  return parse(c);
}

export function setActiveInstitute(customer: any) {
  const institute = JSON.stringify(customer);
  sessionStorage.setItem(SCHOLA_ACTIVE_INSTITUTE, institute);
  localStorage.setItem(SCHOLA_ACTIVE_INSTITUTE, institute);
}
