import React, { FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import MaterialButton from "@mui/material/Button";

export function Button({
  text = "",
  onClick,
  classNames = "",
  type = "button",
  disabled = false,
  to = "",
  children = null,
}: {
  text?: string;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
  onClick?: (val: FormEvent<HTMLButtonElement>) => void;
  classNames?: string;
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean;
  to?: string;
}) {
  const nav = useNavigate();
  return (
    <MaterialButton
      variant="contained"
      onClick={(v) => {
        if (to) {
          nav(to);
        } else {
          onClick?.(v);
        }
      }}
      type={type}
      disabled={disabled}
    >
      {text}
      {children}
    </MaterialButton>
  );
}
